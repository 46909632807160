<template>
  <section class="section is-large">
    <div class="columns is-multiline">
      <div class="column is-10 is-offset-1 register">
        <div class="columns">
          <div class="column left">
            <h1 class="title is-1">Recherche rapide</h1>
            <h2 class="subtitle colored is-4">
              Produire un rapport de recherche
            </h2>
            <p>
              Obtenez toute l'information publique disponible sur un individu
              directment dans votre courriel!
            </p>
          </div>

          <div class="column right has-text-centered">
            <h1 class="title is-4" v-if="state == PersonState">
              Commencez ici
            </h1>
            <h1 class="title is-4" v-if="state == CustomerState">
              Informations du client
            </h1>
            <h1 class="title is-4" v-if="state == PaymentState">Paiement</h1>

            <p class="description" v-if="state == PersonState">
              Inscrivez le nom complet de l'individu recherché et la ville
            </p>
            <p class="description" v-if="state == CustomerState">
              Inscrivez votre nom complet et votre courriel
            </p>
            <p class="description" v-if="state == PaymentState">
              Inscrivez votre carte de crédit pour commander le rapport
            </p>

            <form v-if="state == PersonState || state == CustomerState">
              <div class="field has-addons" v-if="state == PersonState">
                <div class="control">
                  <a class="button is-primary"><i class="fas fa-person"></i></a>
                </div>
                <div class="control">
                  <input
                    class="input is-medium"
                    type="text"
                    placeholder="Nom complet"
                    v-model="search.who"
                  />
                </div>
              </div>

              <div class="field has-addons" v-if="state == PersonState">
                <div class="control">
                  <a class="button is-primary"><i class="fas fa-place"></i></a>
                </div>
                <div class="control">
                  <input
                    class="input is-medium"
                    type="text"
                    placeholder="Ville ou province"
                    v-model="search.where"
                  />
                </div>
              </div>

              <div class="field" v-if="state == CustomerState">
                <div class="control">
                  <input
                    class="input is-medium"
                    type="text"
                    placeholder="Votre nom complet"
                    v-model="search.customer_full_name"
                  />
                </div>
              </div>

              <div class="field" v-if="state == CustomerState">
                <div class="control">
                  <input
                    class="input is-medium"
                    type="email"
                    placeholder="Votre courriel"
                    v-model="search.customer_email"
                  />
                </div>
              </div>

              <button
                class="button is-block is-primary is-fullwidth is-medium"
                @click.prevent="setCustomerState"
                v-if="state == PersonState"
              >
                Commencer
              </button>
              <button
                class="button is-block is-primary is-fullwidth is-medium"
                @click.prevent="setPaymentState"
                v-if="state == CustomerState"
              >
                Dernière étape
              </button>

              <br />

              <small v-if="state == PersonState"
                ><em>Des frais de 4.99$ s'appliquent</em></small
              >
              <small v-if="state == CustomerState"
                ><em
                  >Le rapport de recherche rapide sera envoyé à cette adresse
                  courriel</em
                ></small
              >
            </form>

            <!-- Display a payment form -->
            <form id="payment-form" v-if="state == PaymentState">
              <p>
                Commander rapport de recherche rapide&nbsp;&nbsp;<b>4.99$</b>
              </p>

              <hr />

              <div id="card-element">
                <!--Stripe.js injects the Card Element-->
              </div>

              <button id="submit">
                <div class="spinner hidden" id="spinner"></div>
                <span id="button-text">Pay now</span>
              </button>

              <p id="card-error" role="alert"></p>

              <p class="result-message hidden">
                Paiement effectué avec succès, le rapport sera envoyé à
                <strong>{{ search.customer_email }}</strong>
              </p>
            </form>

            <div class="error" v-if="state == ErrorState">
              <p>Un probleme est survenu...</p>
              <p v-if="errorMessage">{{ errorMessage }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-10 is-offset-1">
        <br />
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <!-- <span class="icon">
                <i class="fas fa-twitter"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fab fa-facebook"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fab fa-instagram"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fab fa-github"></i>
              </span> &emsp;
              <span class="icon">
                <i class="fas fa-envelope"></i>
              </span> -->
            </div>
          </div>

          <div class="level-right">
            <small class="level-item" style="color: var(--textLight)">
              &copy; LandLock Services pour Propriétaires. Tous droits réservés.
            </small>
          </div>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
const PersonState = "person";
const CustomerState = "customer";
const PaymentState = "payment";
const ErrorState = "error";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
const API_NAMESPACE = "api/v1/search";
const PAYMENT_INTENTS_URL = `https://${API_BASE_URL}/${API_NAMESPACE}/payment_intent`;
const SEARCHES_URL = `https://${API_BASE_URL}/${API_NAMESPACE}/search`;

export default {
  name: "RegisterCustomer",

  components: {},

  data: function () {
    return {
      // state of order
      state: PersonState,
      errorMessage: "",

      // params
      search: {
        who: "",
        where: "",
        customer_full_name: "",
        customer_email: "",
        payment_intent_id: "",
      },

      // The items the customer wants to buy
      purchase: {
        items: [
          {
            id: "rapport-recherche-rapide",
          },
        ],
      },
    };
  },

  methods: {
    changeState: function (state) {
      this.state = state;
    },

    setCustomerState: function () {
      this.state = CustomerState;
      this.errorMessage = "";
    },

    setPaymentState: function () {
      this.state = PaymentState;
      this.errorMessage = "";
      this.mountStripe();
    },

    setErrorState: function (message) {
      this.state = ErrorState;
      this.errorMessage = message;
    },

    debug: function () {
      console.debug(`name: ${this.fullName} city: ${this.city}`);
    },

    mountStripe: function () {
      // Disable the button until we have Stripe set up on the page
      document.querySelector("button").disabled = true;

      fetch(PAYMENT_INTENTS_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.purchase),
      })
        .then((result) => result.json())
        .catch((err) => {
          console.error(err);
          this.setErrorState();
        })
        .then((data) => {
          // return on error (empty data)
          console.log(data);
          if (!data) {
            this.setErrorState();
            return;
          }

          if (data.message == "Unauthorized") {
            this.setErrorState("Unauthorized");
            return;
          }

          let elements = this.stripe.elements();

          let style = {
            base: {
              color: "#32325d",
              fontFamily: "Arial, sans-serif",
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#32325d",
              },
            },
            invalid: {
              fontFamily: "Arial, sans-serif",
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          };

          let card = elements.create("card", {
            hidePostalCode: true,
            style: style,
          });
          // Stripe injects an iframe into the DOM
          card.mount("#card-element");

          card.on("change", function (event) {
            // Disable the Pay button if there are no card details in the Element
            document.querySelector("button").disabled = event.empty;
            document.querySelector("#card-error").textContent = event.error
              ? event.error.message
              : "";
          });

          let form = document.getElementById("payment-form");
          form.addEventListener("submit", (event) => {
            event.preventDefault();
            // Complete payment when the submit button is clicked
            this.payWithCard(this.stripe, card, data.client_secret);
          });
        });
    },

    // Calls stripe.confirmCardPayment
    // If the card requires authentication Stripe shows a pop-up modal to
    // prompt the user to enter authentication details without leaving your page.
    payWithCard: function (stripe, card, clientSecret) {
      this.loading(true);
      this.stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: card,
          },
        })
        .then((result) => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message);
          } else {
            // The payment succeeded!
            this.orderComplete(result.paymentIntent.id);
          }
        });
    },

    // Shows a success message when the payment is complete
    orderComplete: function (paymentIntentId) {
      this.loading(false);
      document.querySelector(".result-message").classList.remove("hidden");
      document.querySelector("button").disabled = true;
      this.search.payment_intent_id = paymentIntentId;

      fetch(SEARCHES_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.search),
      })
        .then((result) => result.json())
        .catch((err) => {
          console.error(err);
          this.setErrorState();
        })
        .then((data) => {
          console.log(data);
          if (!data) {
            this.setErrorState();
            return;
          }
        });
    },

    // Show the customer the error from Stripe if their card fails to charge
    showError: function (errorMsgText) {
      this.loading(false);
      var errorMsg = document.querySelector("#card-error");
      errorMsg.textContent = errorMsgText;
      setTimeout(function () {
        errorMsg.textContent = "";
      }, 4000);
    },

    // Show a spinner on payment submission
    loading: function (isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    },
  },

  created() {
    this.PersonState = PersonState;
    this.CustomerState = CustomerState;
    this.PaymentState = PaymentState;
    this.ErrorState = ErrorState;
  },

  mounted: function () {
    // A reference to Stripe.js initialized with your real test publishable API key.
    this.stripe = window.Stripe(STRIPE_PUBLIC_KEY);
  },
};
</script>

<style scoped>
:root {
  --brandColor: hsl(166, 67%, 51%);
  --background: rgb(247, 247, 247);
  --textDark: hsla(0, 0%, 0%, 0.66);
  --textLight: hsla(0, 0%, 0%, 0.33);
}

body {
  background: var(--background);
  height: 100vh;
  color: var(--textDark);
}

.field:not(:last-child) {
  margin-bottom: 1rem;
}

.register {
  margin-top: 10rem;
  background: white;
  border-radius: 10px;
}

.left,
.right {
  padding: 3.25rem;
}

.left {
  border-right: 5px solid var(--background);
}

.left .title {
  font-weight: 800;
  letter-spacing: -2px;
}

.left .colored {
  color: var(--brandColor);
  font-weight: 500;
  margin-top: 1rem !important;
  letter-spacing: -1px;
}

.left p {
  color: var(--textLight);
  font-size: 1.15rem;
}

.right .title {
  font-weight: 800;
  letter-spacing: -1px;
}

.right .description {
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  color: var(--textLight);
  font-size: 1.15rem;
}

.right small {
  color: var(--textLight);
}

input {
  font-size: 1rem;
}

input:focus {
  border-color: var(--brandColor) !important;
  box-shadow: 0 0 0 1px var(--brandColor) !important;
}

input::placeholder {
  color: black;
}

.fab,
.fas {
  color: var(--textLight);
  margin-right: 1rem;
}

/* Stripe */

/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
</style>
