<script setup>
import { useAuthenticator } from '@aws-amplify/ui-vue'
const auth = useAuthenticator()
</script>

<template>
<nav class="navbar is-dark" role="navigation" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand">
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <a class="navbar-item">
          Accueil
        </a>

        <a class="navbar-item">
          À propos
        </a>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            Services
          </a>

          <div class="navbar-dropdown">
            <a class="navbar-item">
              Enquête de pré-location
            </a>
            <a class="navbar-item">
              Recherche rapide
            </a>
            <a class="navbar-item">
              Contactez-nous
            </a>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <a class="navbar-item" @click="auth.signOut" v-if="auth.route === 'authenticated'">
          <i class="fas fa-envelope"></i>&nbsp;Sign Out
        </a>
        <a class="navbar-item" @click="openModal" v-else>
          <i class="fas fa-envelope"></i>&nbsp;Clients
        </a>

        <a class="navbar-item" @click="setLocale">
          <template v-if="locale == 'en'">English</template>
          <template v-else>Francais</template>
        </a>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  data: function() {
    return {
      locale: 'fr'
    }
  },

  methods: {
    setLocale: function() {
      if (this.locale == 'fr') {
        this.locale = 'en'
      } else {
        this.locale = 'fr'
      }
    },

    openModal: function() {
      this.$emit("openModal")
    }
  }
}
</script>

<style scoped>
  .navbar-start, .navbar-end > .navbar-item {
    text-transform: uppercase;
  }
</style>
