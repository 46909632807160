<script setup>
import { Authenticator } from '@aws-amplify/ui-vue'
import '@aws-amplify/ui-vue/styles.css'
</script>

<template>
<div :class="modalClass">
  <div class="modal-background"></div>
  <div class="modal-content">
    <Authenticator initial-state="signIn">
      <template>
        <!-- close modal on sign in -->
        {{closeModal()}}
      </template>

      <template v-slot:header>
        <div style="padding: var(--amplify-space-large); text-align: center">
        <img
          class="logo"
          alt="LandLock logo"
          src="./assets/logo.png"
        />
      </div>
      </template>
    </Authenticator>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="closeModal"></button>
</div>

<section class="hero is-small">
  <div class="hero-body">
    <div class="container">
      <img src="./assets/logo.png">
    </div>
  </div>
</section>
<MainNavbar @openModal="openModal"></MainNavbar>

<RegisterCustomer></RegisterCustomer>
</template>

<script>
import MainNavbar from './components/MainNavbar.vue'
import RegisterCustomer from './components/RegisterCustomer.vue'

export default {
  name: 'App',

  data() {
    return {
      modalActive: false
    }
  },
  
  components: {
    MainNavbar,
    RegisterCustomer,
    Authenticator
  },

  computed: {
    modalClass() {
      if (this.modalActive) {
        return "modal is-active"
      }
      return "modal"
    }
  },

  methods: {
    closeModal: function() {
      this.modalActive = false
    },

    openModal: function() {
      this.modalActive = true
    },

    handleAuthStateChange: function(event) {
      console.log(event)
    }
  }
}
</script>

<style scoped>

</style>
